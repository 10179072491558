// const projectId = 'ef08d1ed3475f138be2551ac23c2a4dd'
import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { arbitrum, avalanche, base, bsc, fantom, gnosis, mainnet, optimism, polygon, } from 'wagmi/chains'
import { walletconnect_projectId } from './utils.js'

export const config = createConfig(
    getDefaultConfig({
      chains: [mainnet, base, arbitrum, avalanche, fantom, gnosis, optimism, bsc, polygon],
      transports: {
          [mainnet.id]: http(mainnet.rpcUrls.default.http[0]),
          [base.id]: http(base.rpcUrls.default.http[0]),
          [arbitrum.id]: http(arbitrum.rpcUrls.default.http[0]),
          // [aurora.id]: http(),
          [avalanche.id]: http(avalanche.rpcUrls.default.http[0]),
          // [zkSync.id]: http(),
          [fantom.id]: http(fantom.rpcUrls.default.http[0]),
          [gnosis.id]: http(gnosis.rpcUrls.default.http[0]),
          // [klaytn.id]: http(),
          [optimism.id]: http(optimism.rpcUrls.default.http[0]),
          [bsc.id]: http(bsc.rpcUrls.default.http[0]),
          [polygon.id]: http(polygon.rpcUrls.default.http[0]),
      },

      walletConnectProjectId: walletconnect_projectId,
      appName: "Betty",
      appDescription: "",
      appUrl: "https://www.betty.finance",
      appIcon: "https://www.betty.finance/assets/logo.png",
    }),
);
const queryClient = new QueryClient();

export const Web3Provider = ({ children }) => {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <ConnectKitProvider
                    customTheme={{
                        "--ck-modal-box-shadow": "0px 2px 4px 0px #00000005",
                        "--ck-border-radius": "20px",
                        "--ck-body-background": "#170b19",
                        "--ck-body-background-secondary": " #F3F4F7",
                        "--ck-body-background-tertiary": " #F3F4F7",
                        
                        "--ck-primary-button-color": "#f5f5f5",
                        "--ck-primary-button-background": "#170b19",
                        "--ck-primary-button-box-shadow": "0 0 0 0 #ffffff",
                        "--ck-primary-button-border-radius": "16px",
                        "--ck-primary-button-font-weight": "600",
                        "--ck-primary-button-hover-color": "#373737",
                        "--ck-primary-button-hover-background": "#F0F2F5",
                        "--ck-primary-button-hover-box-shadow": "0 0 0 0 #ffffff",
                        "--ck-primary-button-active-background": "#F0F2F5",
                        "--ck-primary-button-active-box-shadow": "0 0 0 0 #ffffff",

                        "--ck-spinner-color": " #1cd7b5"

                    }}
                >{children}</ConnectKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
    );
};