import { useEffect } from 'react';
import './App.css';
import './home';
import Navbar from './Navbar';
import Wallet from './Wallet';

function App() {

  useEffect(() => {
    const wheel = document.querySelector('.roulette-wheel');
    
    const handleTouchMove = (e) => {
      e.preventDefault();
    };

    if (wheel) {
      wheel.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      if (wheel) {
        wheel.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  return (
    <div className="App pt-32 w-full min-h-screen text-white overflow-x-hidden">
      <Navbar />
      <div className='flex-center min-h-[70vh]'>
        <div className='w-full flex items-center justify-between md:flex-row flex-col max-w-[1100px] mx-auto'>
          <Wallet />
          <div className='p-5 mb-20 md:mb-0'>
            <div id="app">
              <div>
                <div className="roulette-wheel">
                  <div className="layer-2 wheel" style={{"transform": "rotate(0deg)"}}></div>
                  <div className="layer-3"></div>
                  <div className="layer-4 wheel" style={{"transform": "rotate(0deg)"}}></div>
                  <div className="layer-5"></div>
                  <div className="ball-container" style={{"transform": "rotate(0deg)"}}>
                    <div
                      className="ball"
                      style={{"transform": "translate(0, -163.221px)"}}
                    ></div>
                  </div>
                  <svg width="380" height="380">
                    <circle
                      cx="190"
                      cy="190"
                      r="190"
                      style={{"touch-action": "none"}}
                    ></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="result"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;