import { ConnectKitButton } from 'connectkit';
import React from 'react'

export default function Navbar() {
  return (
    <nav className="stickyNav px-2 md:py-2">
        <div className="navguy px-2">
            <div
                className="border-pinks border-opacity-40 navBoy flex-col flex-center w-full max-w-[1150px] mx-auto overflow-x-hidden">
                <div className="w-full flex items-center justify-between px-5 py-3 md:p-5 nunito">
                    <a href="#" className="flex-center gap-1">
                        <img src="/logo.png" alt="logo" className="h-8" />
                    </a>
                    <div className="gap-5 text-xs py-2 px-10 rounded-full hidden md:flex items-center justify-center dmsans">
                        <a href="#" className="nunito">Whitepaper</a>
                        <a href="/#roadmap" className="nunito">Roadmap</a>
                        <a href="/#tokenomics" className="nunito">Tokenomics</a>
                        <a href="/#faq" className="nunito">FAQ</a>
                    </div>
                    <div className="flex items-center justify-between gap-1">
                        <div className="relative">
                            {/* <a href="https://www.betty.finance" className="text-xs md:text-sm monts rounded-full text-blacks bg-pinks px-4 py-1">Home</a> */}
                              <ConnectKitButton.Custom>
                                {({
                                    isConnected,
                                    isConnecting,
                                    show,
                                    hide,
                                    address,
                                    truncatedAddress,
                                    ensName,
                                }) => {
                                    return (
                                        <button onClick={show} className='text-xs md:text-sm monts rounded-full text-blacks bg-pinks px-4 py-1'>
                                            {isConnected ? truncatedAddress : isConnecting ? "Connecting..." : "Connect Wallet"}
                                        </button>
                                    );
                                }}
                            </ConnectKitButton.Custom>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  )
}