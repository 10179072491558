export const walletconnect_projectId = "ef08d1ed3475f138be2551ac23c2a4dd";

export const url = window.location.href.includes('localhost') ? "http://localhost:3100" : 'https://dex.up.railway.app';

export const coingecko_apiKey = 'CG-ehmRAaAsNbBnXoGNSLCts6CG';
export const chainList = [
  {
    img: "https://assets.coingecko.com/asset_platforms/images/279/thumb/ethereum.png?1706606803",
    name: "Ethereum",
    chainId: 1,
    moralis_chain: "0x1",
    tokenSample: {
      chainId: 1,
      address: "0xc3d688b66703497daa19211eedff47f25384cdc3",
      name: "Compound USDC",
      decimals: 6,
      symbol: "cUSDCv3",
      logoURI:
        "https://tokens.1inch.io/0xc3d688b66703497daa19211eedff47f25384cdc3.png",
    },
  },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/binance.svg",
    name: "Binance",
    chainId: 56,
    moralis_chain: "0x38",
    tokenSample: {
      chainId: 56,
      address: "0x55d398326f99059ff775485246999027b3197955",
      name: "Tether USD",
      decimals: 18,
      symbol: "USDT",
      logoURI:
        "https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    },
   
  },
  {
    img: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
    name: "Solana",
    chainId: 0,
    moralis_chain: "",
    tokenSample: {
      address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
      chainId: 101,
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC",
      logoURI:
        "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    },
    tokenSample2: {
      address: "AujTJJ7aMS8LDo3bFzoyXDwT3jBALUbu4VZhzZdTZLmG",
      chainId: 101,
      decimals: 6,
      name: "BEER",
      symbol: "$BEER",
      logoURI:
        "https://arweave.net/VLGzUkx9E4TBr3rGWehMfR_f42xa92VxA-JvkAIqzw0",
    },
  },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/arbitrum.svg",
    name: "Arbitrum",
    chainId: 42161,
    moralis_chain: "0xa4b1",
    tokenSample: {
      chainId: 42161,
      address: "0x35751007a407ca6feffe80b3cb397736d2cf4dbe",
      name: "Wrapped eETH",
      decimals: 18,
      symbol: "weETH",
      logoURI:
        "https://tokens.1inch.io/0x35751007a407ca6feffe80b3cb397736d2cf4dbe.png",
    },
  },
  // {
  //   img: "https://portal.1inch.dev/assets/icons/network-logos/aurora.svg",
  //   name: "Aurora",
  //   chainId: 1313161554,
  //   tokenSample: {
  //     chainId: 1313161554,
  //     address: "0x09c9d464b58d96837f8d8b6f4d9fe4ad408d3a4f",
  //     name: "Aurigami Token",
  //     decimals: 18,
  //     symbol: "PLY",
  //   },
  // },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/avalanche.svg",
    name: "Avalanche",
    chainId: 43114,
    moralis_chain: "0xa86a",
    tokenSample: {
      chainId: 43114,
      address: "0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be",
      name: "Staked AVAX",
      decimals: 18,
      symbol: "sAVAX",
      logoURI:
        "https://tokens.1inch.io/0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be.png",
    },
  },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/base.svg",
    name: "Base",
    chainId: 8453,
    moralis_chain: "0x2105",
    tokenSample: {
      chainId: 8453,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      name: "Ether",
      decimals: 18,
      symbol: "ETH",
      logoURI:
        "https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png",
      tags: ["native"],
    },
  },

  // {
  //   img: "https://portal.1inch.dev/assets/icons/network-logos/zksync-era.svg",
  //   name: "ZkSync",
  //   chainId: 324,
    
  //   tokenSample: {
  //     chainId: 324,
  //     address: "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91",
  //     name: "Wrapped Ether",
  //     decimals: 18,
  //     symbol: "WETH",
  //     logoURI:
  //       "https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
  //   },
  // },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/fantom.svg",
    name: "Fantom",
    chainId: 250,
    moralis_chain: "0xfa",
    tokenSample: {
      chainId: 250,
      address: "0xa23c4e69e5eaf4500f2f9301717f12b578b948fb",
      name: "Protofi Token",
      decimals: 18,
      symbol: "PROTO",
      logoURI:
        "https://tokens.1inch.io/0xa23c4e69e5eaf4500f2f9301717f12b578b948fb.png",
    },
  },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/gnosis.svg",
    name: "Gnosis",
    chainId: 100,
    moralis_chain: "0x64",
    tokenSample: {
      chainId: 100,
      address: "0x177127622c4a00f3d409b75571e12cb3c8973d3c",
      name: "CoW Protocol Token from Mainnet",
      decimals: 18,
      symbol: "COW",
      logoURI:
        "https://tokens.1inch.io/0x177127622c4a00f3d409b75571e12cb3c8973d3c.png",
    },
  },
  // {
  //   img: "https://portal.1inch.dev/assets/icons/network-logos/klaytn.svg",
  //   name: "Klaytn",
  //   chainId: 8217,
  //   tokenSample: {
  //     chainId: 8217,
  //     address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  //     name: "Klaytn",
  //     decimals: 18,
  //     symbol: "KLAY",
  //     logoURI:
  //       "https://tokens.1inch.io/0xe4f05a66ec68b54a58b17c22107b02e0232cc817.png",
  //   },
  // },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/optimism.svg",
    name: "Optimism",
    chainId: 10,
    moralis_chain: "0xa",
    tokenSample: {
      chainId: 10,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      name: "Ether",
      decimals: 18,
      symbol: "ETH",
      logoURI:
        "https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png",
    },
  },
  {
    img: "https://portal.1inch.dev/assets/icons/network-logos/polygon.svg",
    name: "Polygon",
    chainId: 137,
    moralis_chain: "0x89",
    tokenSample: { 
        "symbol": "MATIC",
        "chainId": 137,
        "name": "MATIC",
        "address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        "decimals": 18,
        "logoURI": "https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
        "eip2612": false,      
    
    },
  },
];



export const initialChartData = [
    {
        "time": "2024-03-19",
        "value": 61924.02
    },
    {
        "time": "2024-03-20",
        "value": 67857.3
    },
    {
        "time": "2024-03-21",
        "value": 65493.22
    },
    {
        "time": "2024-03-22",
        "value": 63816.99
    },
    {
        "time": "2024-03-23",
        "value": 64004.42
    },
    {
        "time": "2024-03-24",
        "value": 67208.33
    },
    {
        "time": "2024-03-25",
        "value": 69892.26
    },
    {
        "time": "2024-03-26",
        "value": 69990.22
    },
    {
        "time": "2024-03-27",
        "value": 69434.46
    },
    {
        "time": "2024-03-28",
        "value": 70789.57
    },
    {
        "time": "2024-03-29",
        "value": 69903.72
    },
    {
        "time": "2024-03-30",
        "value": 69629.99
    },
    {
        "time": "2024-03-31",
        "value": 71312.91
    },
    {
        "time": "2024-04-01",
        "value": 69686.37
    },
    {
        "time": "2024-04-02",
        "value": 65466.81
    },
    {
        "time": "2024-04-03",
        "value": 65986.11
    },
    {
        "time": "2024-04-04",
        "value": 68523.79
    },
    {
        "time": "2024-04-05",
        "value": 67860.53
    },
    {
        "time": "2024-04-06",
        "value": 68913.79
    },
    {
        "time": "2024-04-07",
        "value": 69357.32
    },
    {
        "time": "2024-04-08",
        "value": 71629.36
    },
    {
        "time": "2024-04-09",
        "value": 69129.48
    },
    {
        "time": "2024-04-10",
        "value": 70628.54
    },
    {
        "time": "2024-04-11",
        "value": 70026.43
    },
    {
        "time": "2024-04-12",
        "value": 67158.3
    },
    {
        "time": "2024-04-13",
        "value": 64021.36
    },
    {
        "time": "2024-04-14",
        "value": 65765.64
    },
    {
        "time": "2024-04-15",
        "value": 63447.27
    },
    {
        "time": "2024-04-16",
        "value": 63824.7
    },
    {
        "time": "2024-04-17",
        "value": 61283.74
    },
    {
        "time": "2024-04-18",
        "value": 63511.2
    },
    {
        "time": "2024-04-19",
        "value": 63848.26
    },
    {
        "time": "2024-04-20",
        "value": 64979.21
    },
    {
        "time": "2024-04-21",
        "value": 64955.82
    },
    {
        "time": "2024-04-22",
        "value": 66854.24
    },
    {
        "time": "2024-04-23",
        "value": 66415.06
    },
    {
        "time": "2024-04-24",
        "value": 64263.95
    },
    {
        "time": "2024-04-25",
        "value": 64488.49
    },
    {
        "time": "2024-04-26",
        "value": 63750.63
    },
    {
        "time": "2024-04-27",
        "value": 63434.44
    },
    {
        "time": "2024-04-28",
        "value": 63111.34
    },
    {
        "time": "2024-04-29",
        "value": 63847.54
    },
    {
        "time": "2024-04-30",
        "value": 60633.97
    },
    {
        "time": "2024-05-01",
        "value": 58277.44
    },
    {
        "time": "2024-05-02",
        "value": 59083.85
    },
    {
        "time": "2024-05-03",
        "value": 62917.45
    },
    {
        "time": "2024-05-04",
        "value": 63904.51
    },
    {
        "time": "2024-05-05",
        "value": 64037.5
    },
    {
        "time": "2024-05-06",
        "value": 63168.44
    },
    {
        "time": "2024-05-07",
        "value": 62323.36
    },
    {
        "time": "2024-05-08",
        "value": 61179.81
    },
    {
        "time": "2024-05-09",
        "value": 63077.18
    },
    {
        "time": "2024-05-10",
        "value": 60790.82
    },
    {
        "time": "2024-05-11",
        "value": 60823.19
    },
    {
        "time": "2024-05-12",
        "value": 61459.22
    },
    {
        "time": "2024-05-13",
        "value": 62932.29
    },
    {
        "time": "2024-05-14",
        "value": 61548.48
    },
    {
        "time": "2024-05-15",
        "value": 66245.16
    },
    {
        "time": "2024-05-16",
        "value": 65250.53
    },
    {
        "time": "2024-05-17",
        "value": 67051.35
    },
    {
        "time": "2024-05-18",
        "value": 66927.56
    },
    {
        "time": "2024-05-19",
        "value": 66268.25
    },
    {
        "time": "2024-05-20",
        "value": 71430.23
    },
    {
        "time": "2024-05-21",
        "value": 70141.17
    },
    {
        "time": "2024-05-22",
        "value": 69123.56
    },
    {
        "time": "2024-05-23",
        "value": 67946.35
    },
    {
        "time": "2024-05-24",
        "value": 68548.33
    },
    {
        "time": "2024-05-25",
        "value": 69288.95
    },
    {
        "time": "2024-05-26",
        "value": 68488.29
    },
    {
        "time": "2024-05-27",
        "value": 69386.83
    },
    {
        "time": "2024-05-28",
        "value": 68330.84
    },
    {
        "time": "2024-05-29",
        "value": 67576.81
    },
    {
        "time": "2024-05-30",
        "value": 68346.19
    },
    {
        "time": "2024-05-31",
        "value": 67482.25
    },
    {
        "time": "2024-06-01",
        "value": 67721.6
    },
    {
        "time": "2024-06-02",
        "value": 67738.74
    },
    {
        "time": "2024-06-03",
        "value": 68799.6
    },
    {
        "time": "2024-06-04",
        "value": 70548.51
    },
    {
        "time": "2024-06-05",
        "value": 71117.68
    },
    {
        "time": "2024-06-06",
        "value": 70772.35
    },
    {
        "time": "2024-06-07",
        "value": 69330.51
    },
    {
        "time": "2024-06-08",
        "value": 69303.58
    },
    {
        "time": "2024-06-09",
        "value": 69639.42
    },
    {
        "time": "2024-06-10",
        "value": 69505.57
    },
    {
        "time": "2024-06-11",
        "value": 67319.41
    },
    {
        "time": "2024-06-12",
        "value": 68247
    },
    {
        "time": "2024-06-13",
        "value": 66742.54
    },
    {
        "time": "2024-06-14",
        "value": 66006.13
    },
    {
        "time": "2024-06-15",
        "value": 66188.81
    },
    {
        "time": "2024-06-16",
        "value": 66631.59
    },
    {
        "time": "2024-06-17",
        "value": 66481.73
    },
    {
        "time": "2024-06-18",
        "value": 65158.02
    },
    {
        "time": "2024-06-19",
        "value": 64950.82
    },
    {
        "time": "2024-06-20",
        "value": 64847.89
    },
    {
        "time": "2024-06-21",
        "value": 64119.34
    },
    {
        "time": "2024-06-22",
        "value": 64250.18
    },
    {
        "time": "2024-06-23",
        "value": 63181.02
    },
    {
        "time": "2024-06-24",
        "value": 60270.95
    },
    {
        "time": "2024-06-25",
        "value": 61796.56
    },
    {
        "time": "2024-06-26",
        "value": 60824.43
    },
    {
        "time": "2024-06-27",
        "value": 61558.65
    }
]