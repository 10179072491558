import { ConnectKitButton } from 'connectkit';
import React, { useEffect, useState } from 'react'
import { useAccount, useConnect, useDisconnect } from 'wagmi';

export default function Wallet() {
    const { connectors, connect } = useConnect();
    const { address } = useAccount()
    const { disconnect } = useDisconnect()

    const connectWallet = async () => {
        if(add){
            disconnect();
            // setAdd('')
        } else {
            connect({ connector: connectors[1] });
        }
    }

    const [add, setAdd] = useState(address);
    useEffect(() => {
        setAdd(add);
    }, [address])

  return (
    <div className='w-[90%] mx-auto md:mx-0 max-w-[500px]'>
        <div className='bg-pinks h-12 rounded-xl p-1 flex-center nunito'>
            <p className='bg-bblack w-[60%] py-1.5 rounded-xl text-2xl'>Betty</p>
        </div>
        <div className='border-2 mt-4 border-pinks border-opacity-20 p-4 rounded-xl flex-center flex-col'>
            <h1 className='text-2xl'>Number Guess</h1>
            <input type="number" className='w-[80%] mx-auto rounded-lg bg-pinks outline-none bg-opacity-10 border border-pinks border-opacity-15 mt-2 p-2 text-center text-2xl' placeholder='1' />
            <p className='mt-2 opacity-70'>You automatically lose if you landed at 0</p>
            <img src="/logo.png" className='h-32 my-2' />
            <ConnectKitButton.Custom>
                    {({
                        isConnected,
                        isConnecting,
                        show,
                        hide,
                        address,
                        truncatedAddress,
                        ensName,
                    }) => {
                        return (
                            <button onClick={show} className='bg-pinks w-[70%] mx-auto mt-2 p-2 rounded-xl mb-2'>
                                {isConnected ? truncatedAddress : isConnecting ? "Connecting..." : "Connect Wallet"}
                            </button>
                        );
                    }}
                </ConnectKitButton.Custom>
        </div>
    </div>
  )
}
